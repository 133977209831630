import { Grid, Typography } from '@mui/material';
import React, {Profiler, useState} from 'react';
import Navbar from '../../../components/Navbar';
function MainApplication() {

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));

  return <div>
      <div style={{display: 'flex', flexDirection: 'row'}} >
        <div style={{display: 'flex', flexDirection: 'column', width: '100vw', height: '100vh'}} >
            <div>
                <Navbar />
            </div>
            <div style={{width: '100%', height: '100%', margin: '20px', display: 'flex', flexDirection: 'column', rowGap: '15px', overflow: 'hidden'}}>
                <div style={{width: '93%', height: '100%', backgroundColor: 'white', borderRadius: '10px', padding: '30px', display: 'flex', flexDirection: 'column', alignItems: 'center'}} >
                    <Typography variant='h3' fontSize={'10vh'} >Blaine County Sheriffs Office Application</Typography>
                    <div style={{width: '80%'}} >
                        <div>
                            <Typography variant='h4' >About</Typography>
                        </div>
                    </div>
                </div>
                
                    
                    
                
            </div>
        </div>
      </div>
  </div>;
}

export default MainApplication;
