
import { Avatar, Button, Divider, Grid, Typography } from '@mui/material';
import React, {useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {getAllDepartments} from '../../../../actions/departments'

function DepApplications() {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const departments = useSelector((state) => state.departments).departments
    const user = JSON.parse(localStorage.getItem('user'))
    useEffect(() => {
      dispatch(getAllDepartments()).then((res) => {
          console.log(departments)
      })
    
      
    }, []);
    
  return <div style={{width: '100%'}} >
    <Grid container spacing={5} justifyContent={'flex-start'}>
        {departments?.map((department) => (
            <Grid item >
                <div style={{padding: '20px 35px 20px 35px', backgroundColor: 'white', borderRadius: '10px', width: '325px'}} >
                    <div style={{display: 'flex', flexDirection: 'column', width: '100%', rowGap: '10px'}} >
                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', rowGap: '5px'}} >
                            <Avatar sx={{height: '100px', width: '100px'}} src={department.logo} />
                            <Divider flexItem ><Typography style={{fontWeight: '700'}}>{department.fullName}</Typography></Divider>
                        </div>
                        <div>
                            <Typography fontSize={13} >{department.description_app}</Typography>
                        </div>
                        {department.status == 'open' ? (
                            <Button onClick={() => navigate(`/${department.abreviation}/applications/apply`)} variant='contained' >Apply</Button>
                        ): (
                            <Button disabled onClick={() => navigate(`/${department.abreviation}/applications/apply`)} variant='contained' >Apply</Button>
                        )}
                        
                        <Divider />
                        <div>
                            <Typography>Status: {department.status}</Typography>
                        </div>
                    </div>
                </div>
            </ Grid>
        ))}
    </Grid>
  </div>;
}

export default DepApplications;
