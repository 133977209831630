import * as api from '../api';
import { useNavigate} from 'react-router-dom';

export const getAllDepartments = () => async (dispatch) => {
    
    try {
        const {data} = await api.getAllDepartments();
        if(data){
        dispatch({type: 'GET_ALL_DEPARTMENTS', data: data});
        }
        return data;
    } catch (error) {
        console.log(error.message);
        return
    }
}