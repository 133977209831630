import { Grid, Typography } from '@mui/material';
import React, {Profiler, useState} from 'react';
import Navbar from '../../../components/Navbar';
import Sidebar from './sidebar';
import NoDepMessage from './cards/noDepMessage';
import DepApplications from './cards/depApplications'
function Main() {

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));

  return <div>
      <div style={{display: 'flex', flexDirection: 'row'}} >
        <div>
            <Sidebar />
        </div>
        <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100vh'}} >
            <div>
                <Navbar />
            </div>
            <div style={{width: '100%', height: '100%', margin: '20px', display: 'flex', flexDirection: 'column', rowGap: '15px', overflow: 'hidden'}}>
                <Typography color='#9ea0a3' fontWeight={700} >LEO Dashboard</Typography>
                <div style={{overflowY: 'scroll'}}>
                    {user?.department?.departmentName ? (
                        <Grid container spacing={5} justifyContent={'flex-start'} >
                            <Grid item>
                                
                            </Grid>
                        </Grid>
                    ) : (
                        <Grid container spacing={3} justifyContent={'flex-start'} >
                            <Grid item>
                                <NoDepMessage />
                            </Grid>
                            <Grid item>
                            <DepApplications />
                            </Grid>
                            
                        </Grid>
                        
                        
                        
                    )}
                    </div>
                    
                    
                
            </div>
        </div>
      </div>
  </div>;
}

export default Main;
