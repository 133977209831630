import { Drawer, ListItem, Typography, List, Avatar, ListSubheader, ListItemButton, ListItemIcon, ListItemText, Collapse } from '@mui/material';
import React from 'react';
import SanlyfeIcon from '../../../images/sanlyfeicon.png'
import FeedIcon from '@mui/icons-material/Feed';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import HomeIcon from '@mui/icons-material/Home';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { useNavigate } from 'react-router-dom';

function Sidebar() {
    const navigate = useNavigate();

    const [open, setOpen] = React.useState(false);
    const handleClick = () => {
        setOpen(!open);
      };
    

  return <div style={{backgroundColor: 'rgb(42,47,66)', height: '100vh', width: '15vw'}} >
        <div style={{display: 'flex', flexDirection: 'row', alignContent: 'center', justifyContent: 'center', alignItems: 'center', columnGap: '5%', paddingTop: '10px', marginBottom: '30px'}} >
            <Avatar src={SanlyfeIcon}/>
            <Typography fontSize={'150%'} fontWeight={700} color={'white'} >Sanlyfe</Typography>
        </div>
          <List subheader={<ListSubheader sx={{bgcolor: 'rgb(42,47,66)', color: 'rgb(68,73,92)', fontWeight: '800'}} >Navigation</ListSubheader>} sx={{bgcolor: 'rgb(42,47,66)', color: 'white'}} >
            <ListItemButton onClick={handleClick} >
                <ListItemIcon><HomeIcon style={{color: 'white'}} /></ListItemIcon>
                <ListItemText>Dashbords</ListItemText>
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} >
                <List >
                    <ListItemButton sx={{ pl: 4 }} onClick={() => navigate('/dashboards/leo')} >
                        <ListItemIcon><LocalPoliceIcon style={{color: 'white'}}/></ListItemIcon>
                        <ListItemText >LEO Dashbord</ListItemText>
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }} >
                        <ListItemIcon><AccountBoxIcon style={{color: 'white'}}/></ListItemIcon>
                        <ListItemText >Player Dashbord</ListItemText>
                    </ListItemButton>
                </List>
            </Collapse>
          </List>
          <List subheader={<ListSubheader sx={{bgcolor: 'rgb(42,47,66)', color: 'rgb(68,73,92)', fontWeight: '800'}} >Page Specific</ListSubheader>} sx={{bgcolor: 'rgb(42,47,66)', color: 'white'}} >
            <Typography variant='caption' ></Typography>
          </List>
  </div>;
}

export default Sidebar;
