import { Avatar, Divider, Typography } from '@mui/material';
import React, {useState} from 'react';
import { useSelector } from 'react-redux';

function ProfileCard() {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    console.log(user.avatar)
  return <div style={{padding: '10px 15px 10px 15px', backgroundColor: 'white', borderRadius: '10px'}} >
    <div style={{display: 'flex', flexDirection: 'column', rowGap: '10px'}} >
        <div >
            <Typography variant='h6' color='#001845' fontWeight={'bold'}  >Profile Information</Typography>
            
        </div>
        
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', columnGap: '10px'}}>
            <Avatar src={user?.avatar}/>
            <Typography fontWeight={'bold'} color='#33415C' > {user?.username} </Typography>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', columnGap: '10px'}}>
        <div>
            <Typography fontWeight={'bold'} color='black' > RP Name: </Typography>
            <Typography fontWeight={'bold'} color='black' > RP DOB: </Typography>
        </div>
        <div>
        <Typography fontWeight={500} color='#9ea0a3' > {user?.RP.name} </Typography>
        <Typography fontWeight={500} color='#9ea0a3' > {user?.RP?.DOB} </Typography>
        </div>
        
        
        </div>
        <Divider />
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', columnGap: '10px'}}>
        <div>
            <Typography fontWeight={'bold'} color='black' > Department: </Typography>
            <Typography fontWeight={'bold'} color='black' > Dep Rank </Typography>
        </div>
        <div>
        <Typography fontWeight={500} color='#9ea0a3' > {user?.department?.departmentName || 'Civilian'} </Typography>
        <Typography fontWeight={500} color='#9ea0a3' > {user?.department?.rank || 'None'} </Typography>
        </div>
        
        
        </div>
        
    </div>     
  </div>;
}

export default ProfileCard;
