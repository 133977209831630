import React, {useState, useEffect} from 'react'
import {TextField, Box, Paper, Button, Container, Typography, InputAdornment, CircularProgress} from '@mui/material'
import { withStyles } from '@mui/styles';
import backsvg from '../blob.svg'
import {useNavigate} from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import LockIcon from '@mui/icons-material/Lock';
import {useDispatch, useSelector} from 'react-redux';
import {login} from '../actions/user.js'
import {API_URL} from '../constants.js'

function Login() {
  let navigate = useNavigate();

  const initialstate = {name: '', password: ''};
  const [formData, setFormData] = useState(initialstate);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  let dispatch = useDispatch();
  const userState = useSelector(state => state.user);
  const handleChange = (e) => {
    setFormData({...formData, [e.target.name]: e.target.value})
  }

  useEffect(() => {
    dispatch(login()).then(res => {
      if(res){
        if(res.user.RP?.name){
          console.log('logged in')
          navigate('/welcome');
          return
        };
        navigate('/register');
      }
    })
    
    
  });


  const  handleSubmit = () => {
    window.open(`${API_URL}auth/discordauth`, '_self');
    }
    
  


    return (
        <div style={{backgroundImage: `url(${backsvg})`, height: '100%', width: '100%', backgroundSize: 'cover', position: 'fixed'}}>
          <Container maxWidth='sm' style={{display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'}} >
            <CircularProgress sx={{top: '50%'}} ></CircularProgress>
            </Container>
            
        </div>
    )
}

export default Login
