
import { Avatar, Divider, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

function Announcments() {
    const user = useSelector((state) => state.auth).authData
  return <div style={{padding: '10px 15px 10px 15px', backgroundColor: 'white', borderRadius: '10px', maxWidth: '300px'}} >
    <div style={{display: 'flex', flexDirection: 'column', rowGap: '10px'}} >
        <div >
            <Typography variant='h6' fontWeight={'bold'}  >Community Announcment</Typography>
            
        </div>
        <Divider />
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', columnGap: '10px'}}>
            <Typography fontWeight={'bold'} color='#9ea0a3' > Website in early stage! </Typography>
        </div>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'left', justifyContent: 'left', columnGap: '10px'}}>
            <Typography fontWeight={'bold'} fontSize={'13px'} color='black' > As you probably noticed this website is in a extremly early stage!! It will probably have bugs and some features don't work... sadly. But don't worry! It will all be added eventually and if you have any concerns/questions feel free to contact me on discord @Dani M#4928</Typography>
        </div>
        <Divider></Divider>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'left', justifyContent: 'left', columnGap: '10px'}}>
            <Typography fontSize={'12px'} sx={{flexGrow: 1}} ><b>Author</b>: Dani M </Typography>
            <Typography fontSize={'12px'} ><b>Date:</b> 2022-02-03</Typography>
        </div>
        
        
    </div>     
  </div>;
}

export default Announcments;
