
import { Avatar, Divider, Typography } from '@mui/material';
import React, {useEffect} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {getAllDepartments} from '../../../../actions/departments'

function NoDepMessage() {
    const dispatch = useDispatch();
    const departments = useSelector((state) => state.departments).departments
    const user = JSON.parse(localStorage.getItem('user'))
    useEffect(() => {
      dispatch(getAllDepartments()).then((res) => {
          console.log(departments[0])
      })
    
      
    }, []);
    
  return <div style={{padding: '10px 15px 10px 15px', backgroundColor: 'white', borderRadius: '10px', minWidth: '80vw'}} >
    <div style={{display: 'flex', flexDirection: 'column', rowGap: '10px'}} >
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', columnGap: '10px'}} >
            <Typography variant='h6' fontWeight={'bold'}  >Welcome {user?.username}! You are currently not in any department</Typography>
            
        </div>
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', columnGap: '10px'}}>
            <Typography fontWeight={'bold'} color='#9ea0a3' > Please apply to any of the following departments </Typography>
        </div>
    
        
        
    </div>     
  </div>;
}

export default NoDepMessage;
