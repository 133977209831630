
import { Avatar, Divider, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';

function LeoSelect() {
    const user = useSelector((state) => state.auth).authData
  return <div style={{padding: '10px 15px 10px 15px', backgroundColor: 'white', borderRadius: '10px'}} >
    <div style={{display: 'flex', flexDirection: 'column', rowGap: '10px', maxWidth: '200px'}} >
        <div >
            <Typography variant='h6' fontWeight={'bold'}  >More Cards</Typography>
            
        </div>
        <Divider />
        <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', columnGap: '10px'}}>
            <Typography fontWeight={'bold'} color='#9ea0a3' > Please leave feedback on what informaton you would like to see as soo n as you log in :) </Typography>
        </div>
        
        
    </div>     
  </div>;
}

export default LeoSelect;
