const departmentsReducer = (state = {departments: null}, action) => {
    switch (action.type) {
        case 'GET_ALL_DEPARTMENTS':
            return {...state, departments: action?.data}
        default:
            return state;

    }
} 

export default departmentsReducer;