import { Typography, Container, TextField, Button, InputAdornment, Alert, AlertTitle} from '@mui/material'
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DateRangeIcon from '@mui/icons-material/DateRange';
import AbcIcon from '@mui/icons-material/Abc';
import React, {useState, useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import backsvg from '../blob.svg'
import {useDispatch, useSelector} from 'react-redux';
import {signup, updateUser} from '../actions/user.js'
import {API_URL} from '../constants.js'

function Register() {
    let dispatch = useDispatch();
    const navigate = useNavigate()
    const user = JSON.parse(localStorage.getItem('user'))
    const InitalState = {name: '', DOB: '', STEAM_HEX_64: ''}
    const [newUser, setNewUser] = React.useState();
    const [formData, setFormData] = React.useState(InitalState)
    const [error, setError] = React.useState('')

    useEffect(() => {
      if(user){
        if(!user.RP?.name){
          return;
        };
        navigate('/welcome');
      }else{
        window.open(`${API_URL}auth/discordauth`, '_self');
      }
    }, [])
    

    useEffect(() => {
      if(newUser){
        dispatch(updateUser(newUser)).then(res => {
          navigate('/welcome');
        }).catch(err => {
          setError("Unathorized")
        })
      }
      
    }, [newUser]);

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value})
    }
    const handleSubmit = () => {
      if (formData.DOB.length < 1 || formData.name.length < 1  || formData.STEAM_HEX_64.length < 1) {
        setError('Please fill in all fields')
        return
      }
      //Test
      
      setNewUser({...user, RP: {...formData}})
      

        

    }

    return (
        <div style={{backgroundImage: `url(${backsvg})`, height: '100%', width: '100%', backgroundSize: 'cover', position: 'fixed'}}>
        <Container maxWidth='sm' style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} >
          <div style={{top: '20vh', position: 'absolute', rowGap: '2vh', display: 'flex', flexDirection: 'column'}}>
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
              <Typography style={{fontWeight: 600, fontSize: '50px'}} color={'white'} >First time register</Typography>
              <Typography color={'white'} >Please enter your <b>RP</b> details to create an account</Typography>
            </div>
              <div style={{display: 'grid', flexDirection: 'column', alignItems: 'center', rowGap: '30px', position: 'relative' }} >
                <TextField onChange={handleChange} name='name'sx={{input: {color: 'white'}, fieldset: {borderColor: 'white'}, label: {color: '#BEBEBE'}, "&:hover": {"&& fieldset": {borderColor: '#6C6EE8'}}}} placeholder='Character Name ' InputProps={{startAdornment: (<InputAdornment position='start' ><AccountCircleIcon style={{color:'white'}}/></InputAdornment>)}}/>
                <TextField onChange={handleChange} name='DOB' type='text' sx={{input: {color: 'white'}, fieldset: {borderColor: 'white'}, label: {color: '#BEBEBE'}, "&:hover": {"&& fieldset": {borderColor: '#6C6EE8'}}}} placeholder='Date of Birth' InputProps={{startAdornment: (<InputAdornment position='start' ><DateRangeIcon style={{color:'white'}}/></InputAdornment>)}} />
                <TextField onChange={handleChange} name='STEAM_HEX_64' type='text' sx={{input: {color: 'white'}, fieldset: {borderColor: 'white'}, label: {color: '#BEBEBE'}, "&:hover": {"&& fieldset": {borderColor: '#6C6EE8'}}}} placeholder='Steam 64 Hex' InputProps={{startAdornment: (<InputAdornment position='start' ><AbcIcon style={{color:'white'}}/></InputAdornment>)}}/>
                {error && <Alert severity='error'><b>{error}</b></Alert>}
                <Button onClick={handleSubmit} style={{width: '100%', position: 'relative', borderRadius: '10px'}} variant='contained' >SIGNUP</Button>
              </div>
              
            </div>
          </Container>
          
      </div>
    )
}

export default Register
