import * as api from '../api';
import { useNavigate} from 'react-router-dom';

export const login = (user) => async (dispatch) => {
    
    try {
        const {data} = await api.login();
        console.log(data)
        if(data.user){
            dispatch({type: 'LOGIN', data: data.user});
        }
        return data;
    } catch (error) {
        console.log(error.message);
        return
    }
}

export const logout = () => async (dispatch) => {
    try {
        //api.logout();
        dispatch({type: 'LOGOUT'});
    } catch (error) {
        console.log(error.message);
    }
}

export const updateUser = (newUser) => async (dispatch) => {
    try {
        
        const response = await api.updateUser(newUser);
        console.log(newUser)
        if(response.status == 200){
            console.log("Test")
            dispatch({type: 'UPDATE_USER', data: response.data});
        }
        return response;
    } catch (error) {
        const response = await api.updateUser(newUser);
        console.log(error);
        return response
        
    }
}