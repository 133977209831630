import { Drawer, ListItem, Typography, List, Avatar, ListSubheader, ListItemButton, ListItemIcon, ListItemText, Collapse } from '@mui/material';
import React from 'react';
import SanlyfeIcon from '../../../images/sanlyfeicon.png'
import FeedIcon from '@mui/icons-material/Feed';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import HomeIcon from '@mui/icons-material/Home';
import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ForwardIcon from '@mui/icons-material/Forward';
import ArticleIcon from '@mui/icons-material/Article';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import AssessmentIcon from '@mui/icons-material/Assessment';

function Sidebar() {


    const [open, setOpen] = React.useState(false);
    const [openApp, setOpenApp] = React.useState(false);
    const [openForms, setOpenForms] = React.useState(false);
    const handleClick = () => {
        setOpen(!open);
      };
      const handleClickApp = () => {
        setOpenApp(!openApp);
      };
      const handleClickForms = () => {
        setOpenForms(!openForms);
      };
    

  return <div style={{backgroundColor: 'rgb(42,47,66)', height: '100vh', width: '225px', overflowY: 'auto'}} >
        <div style={{display: 'flex', flexDirection: 'row', alignContent: 'center', justifyContent: 'center', alignItems: 'center', columnGap: '5%', paddingTop: '10px', marginBottom: '30px'}} >
            <Avatar src={SanlyfeIcon}/>
            <Typography fontSize={'150%'} fontWeight={700} color={'white'} >Sanlyfe</Typography>
        </div>
          <List subheader={<ListSubheader sx={{bgcolor: 'rgb(42,47,66)', color: 'rgb(68,73,92)', fontWeight: '800'}} >Navigation</ListSubheader>} sx={{bgcolor: 'rgb(42,47,66)', color: 'white'}} >
            <ListItemButton onClick={handleClick} >
                <ListItemIcon><HomeIcon style={{color: 'white'}} /></ListItemIcon>
                <ListItemText>Dashbords</ListItemText>
                {open ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={open} >
                <List >
                    <ListItemButton sx={{ pl: 4 }} >
                        <ListItemIcon><LocalPoliceIcon style={{color: 'white'}}/></ListItemIcon>
                        <ListItemText >LEO Dashbord</ListItemText>
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }} >
                        <ListItemIcon><AccountBoxIcon style={{color: 'white'}}/></ListItemIcon>
                        <ListItemText >Player Dashbord</ListItemText>
                    </ListItemButton>
                </List>
            </Collapse>
          </List>
          <List subheader={<ListSubheader sx={{bgcolor: 'rgb(42,47,66)', color: 'rgb(68,73,92)', fontWeight: '800'}} >Page Specific</ListSubheader>} sx={{bgcolor: 'rgb(42,47,66)', color: 'white'}} >
          <ListItemButton onClick={handleClickApp} >
                <ListItemIcon><ArticleIcon style={{color: 'white'}} /></ListItemIcon>
                <ListItemText>Applications</ListItemText>
                {openApp ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openApp} >
                <List >
                    <ListItemButton sx={{ pl: 4 }} >
                        <ListItemIcon><AssessmentIcon style={{color: 'white'}}/></ListItemIcon>
                        <ListItemText >Available Apps</ListItemText>
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }} >
                        <ListItemIcon><ForwardIcon style={{color: 'white'}}/></ListItemIcon>
                        <ListItemText >Sent Apps</ListItemText>
                    </ListItemButton>
                </List>
            </Collapse>
            <ListItemButton onClick={handleClickForms} >
                <ListItemIcon><HomeIcon style={{color: 'white'}} /></ListItemIcon>
                <ListItemText>Forms </ListItemText>
                {openForms ? <ExpandLess /> : <ExpandMore />}
            </ListItemButton>
            <Collapse in={openForms} >
                <List >
                    <ListItemButton sx={{ pl: 4 }} >
                        <ListItemIcon><ArticleIcon style={{color: 'white'}}/></ListItemIcon>
                        <ListItemText >Available Forms</ListItemText>
                    </ListItemButton>
                    <ListItemButton sx={{ pl: 4 }} >
                        <ListItemIcon><ForwardIcon style={{color: 'white'}}/></ListItemIcon>
                        <ListItemText >Sent Forms</ListItemText>
                    </ListItemButton>
                </List>
            </Collapse>
          </List>
          <List subheader={<ListSubheader sx={{bgcolor: 'rgb(42,47,66)', color: 'rgb(68,73,92)', fontWeight: '800'}} >Supervisors</ListSubheader>} sx={{bgcolor: 'rgb(42,47,66)', color: 'white'}} >
          <ListItemButton>
                <ListItemIcon><SupervisedUserCircleIcon style={{color: 'white'}} /></ListItemIcon>
                <ListItemText>Manage Users</ListItemText>
            </ListItemButton>
          <ListItemButton>
                <ListItemIcon><HomeIcon style={{color: 'white'}} /></ListItemIcon>
                <ListItemText>Manage App's</ListItemText>
            </ListItemButton>
            
            <ListItemButton >
                <ListItemIcon><HomeIcon style={{color: 'white'}} /></ListItemIcon>
                <ListItemText>Manage Form's </ListItemText>
            </ListItemButton>
            
          </List>
          
  </div>;
}

export default Sidebar;
