import axios from 'axios';
import { API_URL } from '../constants.js';
const API = axios.create({baseURL: API_URL});

export const signup = (newUser) => API.post('/users/signup', newUser);
export const login = () => API.get('/auth/login', {withCredentials: true, credentials: 'include'});
export const logout = () => API.get('/auth/logout', {withCredentials: true, credentials: 'include'});;

export const updateUser = (user) => API.patch(`/users/${user.id}/update`, user,{withCredentials: true, credentials: 'include'});

export const getAllDepartments = () => API.get('/departments')