import React, {useState} from 'react'
import {Avatar, Button, Container, Divider, IconButton, Paper, Typography} from '@mui/material'
import { useNavigate} from 'react-router-dom';
import BCSOemblem from '../images/BCSOemblem.png'
import LSPDemblem from '../images/LSPDemblem.png'
import DiscordIcon from '../images/discordicon.png'
import {API_URL} from '../constants.js'
const Home = () => {
    let navigate = useNavigate();
    const handleClick = () => {
        navigate(`${API_URL}auth/discordauth`);
    }

    const  login = () => {
        window.open(`${API_URL}auth/discordauth`, '_self');
    }

    return (
        <div style={{display: 'flex', flexDirection: 'column'}}>
            <Button onClick={() => login()} style={{backgroundColor: 'rgb(114,137,218)', color: 'white', position: 'fixed', right: '2vw', top: '1vh'}} ><img width={40} src={DiscordIcon} ></img><Typography style={{paddingLeft: '10px'}} >Login with Discord</Typography></Button>
                    <div style={{minheight: '100vh', width: '100vw', backgroundImage: 'url(https://wallpapercave.com/wp/wp4421387.jpg)' }}  >
                        <div style={{backgroundImage: 'linear-gradient(to top, rgba(30,31,35,1), rgba(30,31,35,0.6))', width: '100%', height: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', paddingBottom: '50px'}}>
                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center',top: '50px', position: 'unset', rowGap: '40px', maxWidth: '745px'}}>
                                <div>
                                    <div>
                                        <Typography variant="h1" style={{color: 'white', fontWeight: '700'}}>Sanlyfe Roleplay</Typography>
                                    </div>
                                    <div style={{alignSelf: 'center'}} >
                                        <Divider sx={{'&.MuiDivider-root': {'&::before': {borderTop: 'thin solid white'}, '&::after': {borderTop: 'thin solid white'}}}}><Typography color={'rgb(143,144,147)'} variant='h3' style={{fontSize: '20px'}} ><i>Server for the community by the community</i></Typography></Divider>
                                    </div>
                                </div>
                                <div>
                                    <Paper elevation={20} style={{backgroundColor: 'rgb(35,35,40, 0.5)', padding: '20px', paddingLeft: '30px', paddingRight: '30px', borderRadius: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '10px'}}>
                                        <div>
                                            <Typography color={'rgb(255,255,255)'} variant='h3' style={{fontSize: '25px'}} >About Sanlyfe</Typography>
                                        </div>
                                        <Divider flexItem style={{backgroundColor: 'rgb(35,35,40, 0.7)'}} ></Divider>
                                        <div>
                                            <Typography color={'rgb(198,199,200)'} >Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.</Typography>
                                        </div>
                                    </Paper>
                                </div>

                                <div  >
                                    <Paper elevation={20} style={{backgroundColor: 'rgb(35,35,40, 0.5)', padding: '20px', paddingLeft: '30px', paddingRight: '30px', borderRadius: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '15px'}} >
                                    <div>
                                            <Typography color={'rgb(255,255,255)'} variant='h3' style={{fontSize: '25px'}} >Departments</Typography>
                                        </div>
                                        <Divider flexItem style={{backgroundColor: 'rgb(35,35,40, 0.7)'}} ></Divider>
                                        <div style={{display: 'flex', flexDirection: 'row', columnGap: '30px' }} >
                                        <Paper elevation={0} style={{backgroundColor: 'rgb(35,35,40, 0.5)', padding: '20px', paddingLeft: '30px', paddingRight: '30px', borderRadius: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '15px', width: '50%', justifyContent: 'space-between'}} >
                                            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}} >
                                                <Avatar sx={{height: '200px', width: '200px'}} src={BCSOemblem}/>
                                                <div style={{width: '100%'}} >
                                                    <Divider flexItem sx={{'&.MuiDivider-root': {'&::before': {borderTop: 'thin solid white'}, '&::after': {borderTop: 'thin solid white'}}, width: '100%'}}><Typography color={'rgb(255,255,255)'} variant='h3' style={{fontSize: '30px'}} >BCSO</Typography></Divider>
                                                </div>
                                                <div>
                                                    <Typography color={'rgb(198,199,200)'} >Is your dream to help the community and ensure safety for members of the public? If that is the case the Sheriff's Office is perfect for you! From the dusty desert to the bustling city streets, from the rocks of Mount Chiliad to the forests of Paleto we are there to render safety and protection. You will join a professional team where everyday is a new adventure. There will be hardships and challenges, but the BCSO team will always have your back.</Typography>
                                                </div>
                                            </div>
                                            <Button variant='contained' sx={{fontWeight: 'bold', borderRadius: '8px', fontSize: '1rem', width: '80%' }}>APPLY</Button>
                                        </Paper>

                                        <Paper elevation={0} style={{backgroundColor: 'rgb(35,35,40, 0.5)', padding: '20px', paddingLeft: '30px', paddingRight: '30px', borderRadius: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', rowGap: '15px', width: '50%', justifyContent: 'space-between'}} >
                                        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                                            <Avatar sx={{height: '200px', width: '200px'}} src={LSPDemblem}/>
                                            <div style={{width: '100%'}} >
                                                <Divider flexItem sx={{'&.MuiDivider-root': {'&::before': {borderTop: 'thin solid white'}, '&::after': {borderTop: 'thin solid white'}}, width: '100%'}}><Typography color={'rgb(255,255,255)'} variant='h3' style={{fontSize: '30px'}} >LSPD</Typography></Divider>
                                                </div>
                                                <div>
                                                    <Typography color={'rgb(198,199,200)'} >Welcome to LSPD, a department run by the best, only for the best. We at LSPD have a 6 month training program, to train your officers into super soldiers. From here, you can rank up, explore the system and even be a part of our very own SWAT team. But whatever you do, we treat each other like a family and welcome anyone to the department, so join here now and be apart of the LSPD family!</Typography>
                                                </div>
                                        </div>
                                            <Button variant='contained' sx={{fontWeight: 'bold', borderRadius: '8px', fontSize: '1rem', width: '80%' }}>APPLY</Button>
                                        </Paper>
                                        </div>
                                    </Paper>
                                </div>

                            </div>
                            
                        </div>
                        
                        
                    </div>
                    <div style={{width: '100vw', height: '10vw', backgroundColor: 'rgb(17,18,21)',display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between'}} >
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', columnGap: '20vw' }} >
                                <div style={{top: '5vh', position: 'relative', height: 'fit-content'}} >
                                    <Typography style={{fontSize: '30px'}} color='rgb(195,195,196)'>CONTACT</Typography>
                                </div >
                                <div style={{top: '5vh', position: 'relative', height: 'fit-content'}}>
                                    <Typography style={{fontSize: '30px'}} color='rgb(195,195,196)'>QUICK LINKS</Typography>
                                </div>
                                
                            </div>
                            <div style={{textAnchor: 'bottom'}}>
                                <Typography color={'rgb(77,78,80)'} >Boring legal information such as copyrights will be typed here</Typography>
                            </div>
                        </div>
        </div>
    )
}

export default Home
