import { Grid, Typography } from '@mui/material';
import React, {Profiler, useState} from 'react';
import Navbar from '../../../components/Navbar';
import Sidebar from './sidebar';
import LeoSelect from './cards/LeoSelect';
import ProfileCard from './cards/ProfileCard';
import Announcments from './cards/announcments';

function Main() {

    const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));

  return <div>
      <div style={{display: 'flex', flexDirection: 'row'}} >
        <div>
            <Sidebar />
        </div>
        <div style={{display: 'flex', flexDirection: 'column', width: '100%', height: '100%'}} >
            <div>
                <Navbar />
            </div>
            <div style={{width: '100%', height: '100%', margin: '20px', display: 'flex', flexDirection: 'column', rowGap: '15px', overflow: 'scroll'}}>
                <Typography color='#9ea0a3' fontWeight={700} >Welcome {user?.username}!</Typography>
                <Grid container spacing={5} justifyContent={'flex-start'} >
                    <Grid item >
                        <ProfileCard />
                    </Grid>
                    <Grid item>
                        <Announcments />
                    </Grid>
                    <Grid item>
                        <LeoSelect />
                    </Grid>
                    
                    
                </Grid>
            </div>
        </div>
      </div>
  </div>;
}

export default Main;
