import { AppBar, Avatar, Button, Menu, MenuItem, Toolbar, Typography, Divider} from '@mui/material';
import React, { useEffect, useState } from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useNavigate} from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import SettingsIcon from '@mui/icons-material/Settings';
import LogoutIcon from '@mui/icons-material/Logout';
import { logout } from '../actions/user';
import { API_URL } from '../constants';

function Navbar() {
  const dispatch = useDispatch();
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
    const navigate = useNavigate()
    
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleCloseProfile = () => {
      setAnchorEl(null);
    };


    const handleClickProfile = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleLogout = () => {
      dispatch(logout());
      window.open(`${API_URL}auth/logout`, '_self');
    }

    useEffect(() => {
        if(!user) {
            navigate('/login');
        }
        console.log(user)
    })
  return <div style={{minWidth: '100%'}} >
      <AppBar position='relative' sx={{backgroundColor: 'white'}} >
        <Toolbar style={{color: 'black'}} >
          <Typography sx={{flexGrow: 1}} >Contact 'Dani M#4928' for support</Typography>
          <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', columnGap: '20px', backgroundColor: '#eff1f2', borderRadius: '10px'}} >
          <Button sx={{columnGap: '10px', color: 'black', textTransform: 'none'  }} onClick={handleClickProfile}>
            <Avatar src={user.avatar} ></Avatar>
            <div style={{display: 'flex', alignItems: 'flex-start', flexDirection: 'column'}} >
              <Typography >{user?.username}</Typography>
              <Typography fontSize={11} color={'#9ea0a3'} >{user?.rank}</Typography>
            </div>
            {open? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </Button>
            
          </div>
          <SettingsIcon style={{marginLeft: '10px'}} />
        </Toolbar>
      </AppBar>


      <Menu onClick={handleCloseProfile} onClose={handleCloseProfile} anchorEl={anchorEl} open={open} >
        <MenuItem> 
          <Typography>Player Information</Typography>
        </MenuItem>
        <MenuItem> 
          <Typography>Department Information</Typography>
        </MenuItem>
        <Divider />
        <MenuItem sx={{columnGap: '10px'}} onClick={handleLogout} >
          <LogoutIcon />
          <Typography>Logout</Typography>
        </MenuItem>

      </Menu>
  </div>;
}

export default Navbar;
